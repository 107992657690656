import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { useAtom } from 'jotai'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { Modal } from '../../common/Modal'
import { ModalBody } from '../../common/Modal/ModalBody'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { AskBoardNewMessageModalContent } from '../AskBoardNewMessageModalContent'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { chatAskBoardSchema } from '../../../forms/schemas/chat_room'
import {
  IChatMessageCreate,
  IChatMessageModel,
} from '../../../interfaces/models/chat-messages.interfaces'
import { NChat } from '../../../interfaces/services/chat.interfaces'
import { socket, SocketEvent } from '../../../sockets'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'

interface AskBoardNewMessageModalProps {
  show: boolean
  close: () => void
}

export const AskBoardNewMessageModal = observer(
  ({ show, close }: AskBoardNewMessageModalProps): JSX.Element => {
    const { authenticationStore, chatMessageStore, chatRoomStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const [, setChatHeaderState] = useAtom(chatHeaderStateAtom)
    const userId = authenticationStore.userId as string

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('chatAskBoardNewMessage.flashMessage.sendQuestionFailure')
      )
    }

    const onError = (): void => {
      showErrorMessage()
    }

    const onSubmit = async (
      data: InferType<typeof chatAskBoardSchema>
    ): Promise<void> => {
      const body: NChat.NCreateRoom.IRequestBody = {
        name: data.subject,
        type: 'questions',
        societyId: data.society,
        membersList: [{ userId }],
      }
      const room = await chatRoomStore.createRoom(body)

      if (!room) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
        return
      }

      try {
        const _data: IChatMessageCreate = {
          ...data,
          roomId: room._id,
          authorId: userId,
        }
        const _socket = socket.getSocket()

        if (!_socket) {
          throw Error('Socket connection was not successfully established')
        }

        _socket?.emit(
          SocketEvent.SEND_MESSAGE,
          _data,
          (newMessage: IChatMessageModel) => {
            chatMessageStore.setChatMessages([newMessage])
            chatRoomStore.chatRooms
              .get(room._id)
              ?.updateLastMessageDate(Date.parse(newMessage.createdAt))
          }
        )

        setChatHeaderState(ChatHeaderState.None)
        chatRoomStore.setSelectedChatRoom(room._id)
        close()
        setToastNotification(
          ToastType.SUCCESS,
          translate('chatAskBoardNewMessage.flashMessage.sendQuestionSuccess')
        )
      } catch (error) {
        captureException(error)
        showErrorMessage()
      }
    }

    const loading = chatRoomStore.creatingChatRoom === 'pending'

    return (
      <Modal show={show} size="sm">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('chatAskBoardNewMessage.title')}
            </ModalHeader>
          ),
          body: (
            <ModalBody className="max-h-[600px] overflow-y-auto">
              <AskBoardNewMessageModalContent
                onError={onError}
                onSubmit={onSubmit}
                onClose={close}
                loading={loading}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
