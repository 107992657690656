import { AxiosResponse } from 'axios'
import { NChat } from '../interfaces/services/chat.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const CHAT_ROOM_POPULATE = [
  'users',
  'users.avatar',
  'media',
  'societies',
  'societies.media',
  'messages.limit.10',
  'messages.media',
  'messages.users',
]

export const getUserRooms = (
  query?: NChat.NGetUserRooms.IRequestQuery
): Promise<AxiosResponse<NChat.NGetUserRooms.IResponse>> => {
  return axiosAuth.get<NChat.NGetUserRooms.IResponse>(
    createUrl('/chat/get-user-rooms', {
      populate: CHAT_ROOM_POPULATE.join(';'),
      ...query,
    })
  )
}

export const getUserRoomsOneOnOne = (
  query?: NChat.NGetUserRoomsOneOnOne.IRequestQuery
): Promise<AxiosResponse<NChat.NGetUserRoomsOneOnOne.IResponse>> => {
  return axiosAuth.get<NChat.NGetUserRoomsOneOnOne.IResponse>(
    createUrl('/chat/get-user-rooms-one-on-one', {
      populate: CHAT_ROOM_POPULATE.join(';'),
      ...query,
    })
  )
}

export const getUserRoomsInterests = (
  query?: NChat.NGetUserRoomsInterests.IRequestQuery
): Promise<AxiosResponse<NChat.NGetUserRoomsInterests.IResponse>> => {
  return axiosAuth.get<NChat.NGetUserRoomsInterests.IResponse>(
    createUrl('/chat/get-user-rooms-interests', {
      populate: CHAT_ROOM_POPULATE.join(';'),
      ...query,
    })
  )
}

export const getUserRoomsQuestions = (
  query?: NChat.NGetUserRoomsQuestions.IRequestQuery
): Promise<AxiosResponse<NChat.NGetUserRoomsQuestions.IResponse>> => {
  return axiosAuth.get<NChat.NGetUserRoomsQuestions.IResponse>(
    createUrl('/chat/get-user-rooms-questions', {
      populate: CHAT_ROOM_POPULATE.join(';'),
      ...query,
    })
  )
}

export const getUserRoomsBoard = (
  query?: NChat.NGetUserRoomsBoard.IRequestQuery
): Promise<AxiosResponse<NChat.NGetUserRoomsBoard.IResponse>> => {
  return axiosAuth.get<NChat.NGetUserRoomsBoard.IResponse>(
    createUrl('/chat/get-user-rooms-board', {
      populate: CHAT_ROOM_POPULATE.join(';'),
      ...query,
    })
  )
}

export const patchRoom = (
  id: string,
  body: NChat.NPatchRoom.IRequestBody,
  parameters?: {
    [key: string]: string
  }
): Promise<AxiosResponse<NChat.NPatchRoom.IResponse>> => {
  return axiosAuth.patch<NChat.NPatchRoom.IResponse>(
    createUrl(`/chat/room/${id}`, {
      populate: CHAT_ROOM_POPULATE.join(';'),
      ...parameters,
    }),
    body
  )
}

export const removeRoom = (
  id: string
): Promise<AxiosResponse<NChat.NDelRoom.IResponse>> => {
  return axiosAuth.delete<NChat.NDelRoom.IResponse>(
    createUrl(`/chat/room/${id}`)
  )
}

export const getRoomById = (
  id: string
): Promise<AxiosResponse<NChat.NGetRoomById.IResponse>> => {
  return axiosAuth.get<NChat.NGetRoomById.IResponse>(
    createUrl(`/chat/room/${id}`, {
      populate: CHAT_ROOM_POPULATE.join(';'),
    })
  )
}

export const getUserRoom = (
  id: string
): Promise<AxiosResponse<NChat.NGetUserRoom.IResponse>> => {
  return axiosAuth.get<NChat.NGetUserRoom.IResponse>(
    createUrl(`/chat/get-user-room/${id}`, {
      populate: CHAT_ROOM_POPULATE.join(';'),
    })
  )
}

export const createRoom = (
  body: NChat.NCreateRoom.IRequestBody
): Promise<AxiosResponse<NChat.NCreateRoom.IResponse>> => {
  return axiosAuth.post<NChat.NCreateRoom.IResponse>(
    createUrl(`/chat/room`),
    body
  )
}

export const joinRooms = (
  body: NChat.NJoinRooms.IRequestBody
): Promise<AxiosResponse<NChat.NJoinRooms.IResponse>> => {
  return axiosAuth.post<NChat.NJoinRooms.IResponse>(
    createUrl(`/chat/join-rooms`),
    body
  )
}

export const leaveRooms = (
  body: NChat.NLeaveRooms.IRequestBody
): Promise<AxiosResponse<NChat.NLeaveRooms.IResponse>> => {
  return axiosAuth.post<NChat.NLeaveRooms.IResponse>(
    createUrl(`/chat/leave-rooms`),
    body
  )
}

export const setRoomRead = (
  id: string
): Promise<AxiosResponse<NChat.NSetRoomRead.IResponse>> => {
  return axiosAuth.post<NChat.NSetRoomRead.IResponse>(
    createUrl(`/chat/set-room-read/${id}`)
  )
}

export const toggleHideUntilNotification = (
  id: string,
  value: boolean
): Promise<AxiosResponse<NChat.NToggleHideUntilNotification.IResponse>> => {
  return axiosAuth.post<NChat.NToggleHideUntilNotification.IResponse>(
    createUrl(`/chat/toggle-hide-until-notification/${id}`),
    {
      value,
    }
  )
}

export const CHAT_MESSAGES_POPULATE = ['users', 'users.avatar', 'media']

export const getRoomMessages = (
  roomId: string,
  query?: NChat.NGetUserRooms.IRequestQuery
): Promise<AxiosResponse<NChat.NGetRoomMessages.IResponse>> => {
  return axiosAuth.get<NChat.NGetRoomMessages.IResponse>(
    createUrl(`/chat/get-room-messages/${roomId}`, {
      populate: CHAT_MESSAGES_POPULATE.join(';'),
      ...query,
    })
  )
}
