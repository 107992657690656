import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Societies } from './Societies'
import { DiscountCodes } from './DiscountCodes'
import { Users } from './Users'
import { BoappaSuperadmin } from '../../../components/settings/BoappaSuperadmin'
import { DataExport } from './DataExport'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../components/common/ViewBase'
import { RouteErrorView } from '../../error/RouteErrorView'
import { Container } from '../../../components/common/Container'
import { SuperAdminViewTabs, useSuperAdminTabs } from './useSuperAdminTabs'
import { AuditLogs } from './AuditLogs'
import { PendingInvoices } from './PendingInvoices'

export const SuperAdminView = observer((): JSX.Element | null => {
  useDocumentTitle(reverseDocumentTitle('admin'))
  const { translate } = useAppTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { getTabUrl, getTabState } = useSuperAdminTabs()

  const [activeTab, setActiveTab] = useState<SuperAdminViewTabs>(
    getTabState(location.pathname)
  )

  // When a new activeTab is set, we change the navigation to that tab as well
  useEffect(() => {
    const url = getTabUrl(activeTab)
    if (location.pathname !== url) {
      navigate(url)
      setActiveTab(getTabState(url))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const tabs = [
    {
      title: translate('adminView.tabBar.users'),
    },
    {
      title: translate('adminView.tabBar.societies'),
    },
    {
      title: translate('adminView.tabBar.logInAsUser'),
    },
    {
      title: translate('adminView.tabBar.pendingInvoices'),
    },
    {
      title: translate('adminView.tabBar.discountCodes'),
    },
    {
      title: translate('adminView.tabBar.auditLogs'),
    },
    {
      title: translate('adminView.tabBar.extractData'),
    },
  ]

  return (
    <ErrorBoundary>
      <Container className="h-full">
        <ViewBase
          childrenClassName="h-full"
          tabBar={{ tabs, setActiveTab, activeTab }}
        >
          <Routes>
            <Route
              path="users"
              element={<Users />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="societies"
              element={<Societies />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="twilight-zone"
              element={<BoappaSuperadmin />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="pending-invoices"
              element={<PendingInvoices />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="audit-logs"
              element={<AuditLogs />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="discount-codes"
              element={<DiscountCodes />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="data-export"
              element={<DataExport />}
              errorElement={<RouteErrorView />}
            />
          </Routes>
        </ViewBase>
      </Container>
    </ErrorBoundary>
  )
})
